import logo from "../../assets/images/logo-dap-rent.png";

export function LoaderView() {
  return (
    <div className="flex w-full h-full overflow-y-hidden bg-white fixed top-0 left-0">
      <img
        className="h-[200px] mx-auto my-auto animate-pulse"
        src={logo}
        alt="loader"
      />
    </div>
  );
}
