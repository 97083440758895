import { NewSet } from "../models/NewSet";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function addSet(
  set: NewSet,
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.addSet(set);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
