import { ProductData } from "../../../domain/models";

function SetCatalogueItem({
  productName,
  productImage,
  navigateToProduct,
}: {
  productName: string;
  productImage: string;
  navigateToProduct: (productId: string) => void;
}) {
  return (
    <div
      key={productName}
      className="group relative"
      onClick={() => navigateToProduct(productName)}
    >
      <div className="relative aspect-h-1 aspect-w-1 w-full">
        <img
          src={productImage}
          alt="Example"
          className="w-full h-full object-contain rounded-lg"
        />
      </div>

      <div className="mt-4 flex flex-col justify-between">
        <h3 className="text-lg font-medium text-gray-900 mx-auto">
          <span aria-hidden="true" className="absolute inset-0" />
          {productName}
        </h3>
      </div>
    </div>
  );
}

export default SetCatalogueItem;
