import { BackToProductButton } from "./BackToProductButton";
import { ProductInfoComponent } from "./ProductInfoComponent";
import { ProductFormComponent } from "./ProductFormComponent";
import { ProductData } from "../../../domain/models/ProductData";
import { useNavigate } from "react-router-dom";

export function ProductDetailsComponent({
  productData,
  showBackButton = true,
}: {
  productData: ProductData;
  showBackButton: boolean;
}) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col justify-between h-full w-full md:w-1/2 max-w-xs mx-auto space-y-4 min-h-128">
      {showBackButton && <BackToProductButton goBack={goBack} />}
      <ProductInfoComponent product={productData} />
      <ProductFormComponent
        id={productData.id}
        category={productData.category}
        title={productData.title}
        description={productData.description}
        price={productData.price}
        discount={productData.discount}
        images={productData.images}
      />
    </div>
  );
}
