export function saveLocalData(cart: any) {
  localStorage.setItem(
    process.env.REACT_APP_CART_LOCAL_STORAGE!,
    JSON.stringify(cart)
  );
}

function getLocalData(): string | null {
  if (localStorage.getItem(process.env.REACT_APP_CART_LOCAL_STORAGE!)) {
    return JSON.parse(
      localStorage.getItem(process.env.REACT_APP_CART_LOCAL_STORAGE!)!
    );
  } else {
    return null;
  }
}

export function setLocalData(setCart: any) {
  const localData = getLocalData();
  if (localData !== undefined && localData !== null) {
    if (Array.isArray(localData)) {
      setCart([...localData]);
    } else {
      setCart([localData]);
    }
  }
}
