import { NewProductData } from "../models";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function editProduct(
  documentId: string,
  product: NewProductData,
  currentUrls: string[],
  coverImage: File | undefined,
  productImages: File[],
  imagesToDelete: string[],
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.editProduct(
    documentId,
    product,
    currentUrls,
    coverImage,
    productImages,
    imagesToDelete
  );
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
