import { PendingQuote } from "../models/PendingQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function getPendingQuotes(
  repository: QuotesRepository
): Promise<PendingQuote[]> {
  const result = await repository.getPendingQuotes();
  if (result instanceof Success) {
    return result.value;
  } else {
    return [];
  }
}
