import { ProductData } from "../models";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function getAllProducts(
  repository: ProductsRepository
): Promise<ProductData[]> {
  const result = await repository.getAllProducts();
  if (result instanceof Success) {
    return result.value;
  } else {
    return [];
  }
}
