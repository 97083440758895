import { useState, useEffect } from "react";
import { ProductData } from "../../../domain/models";
import { useClientModelController } from "../../hook/useClientModelController";
import { ProductsRepository } from "../../../domain/repository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import ProductCatalogueItem from "../admin/ProductCatalogueItem";
import { useNavigate, useParams } from "react-router-dom";

export function ProductSuggestions({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const { handleGetAllProducts } = useClientModelController(
    productsRepository,
    quotesRepository
  );
  const [productList, setProductList] = useState<ProductData[]>([]);
  const { category, subcategory } = useParams();

  const navigateToProduct = (productId: string) => {
    navigate(`/catalog/${category}/${subcategory ?? "product"}/${productId}`);
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    const products: ProductData[] = await handleGetAllProducts();
    var randomProducts: ProductData[] = [];
    if (products.length > 4) {
      randomProducts = getRandomElementsFromArray(products, 4);
    } else {
      randomProducts = products;
    }
    setProductList(randomProducts);
  };
  function getRandomElementsFromArray(
    array: ProductData[],
    numElements: number
  ) {
    const copyArray = array.slice();
    const randomElements = [];

    for (let i = 0; i < numElements && copyArray.length > 0; i++) {
      const randomIndex = Math.floor(Math.random() * copyArray.length);
      randomElements.push(copyArray.splice(randomIndex, 1)[0]);
    }

    return randomElements;
  }
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-2xl font-bold tracking-tight text-gray-900">
          Potrebbe interessarti anche
        </h2>

        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {productList.map((product) => (
            <ProductCatalogueItem
              key={product.id}
              product={product}
              navigateToProduct={(productId) => navigateToProduct(productId)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
