import { useEffect } from "react";
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
export default function ScrollToTop() {
  const useBackButton = () => {
    const navType = useNavigationType();
    return navType === NavigationType.Pop;
  };
  const { pathname } = useLocation();

  const isPop = useBackButton();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, isPop]);

  return null;
}
