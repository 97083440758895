import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export function BackToProductButton({ goBack }: { goBack: () => void }) {
  return (
    <button
      aria-label="back-to-products"
      className="w-full border border-palette-primary text-palette-primary text-lg font-medium pt-2 pb-2 leading-relaxed flex 
      justify-center items-center focus:ring-1 focus:ring-palette-light focus:outline-none hover:bg-palette-lighter rounded-lg"
      onClick={goBack}
    >
      <FontAwesomeIcon icon={faArrowLeft} className="w-4 mr-2 inline-flex" />
      Torna al catalogo
    </button>
  );
}
