import { ProcessedQuote } from "../models/ProcessedQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function getProcessedQuotes(
  repository: QuotesRepository
): Promise<ProcessedQuote[]> {
  const result = await repository.getProcessedQuotes();
  if (result instanceof Success) {
    return result.value;
  } else {
    return [];
  }
}
