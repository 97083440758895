import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { NewProductData, Set } from "../../../domain/models";
import { useAdminModelController } from "../../hook/useAdminModelController";
import { ProductsRepository } from "../../../domain/repository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import { convertToBase64, resizeFile } from "../../../utils/imageUtils";
import { categories, findSubcategories } from "../../../utils/productUtils";

export default function AddProductDialogComponent({
  productRepository,
  quotesRepository,
  allSets,
  open,
  onClose,
  onConfirm,
}: {
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
  allSets: Set[] | null;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [coverImagePreview, setCoverImagePreview] = useState<string>("");
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [formData, setFormData] = useState<NewProductData>({
    category: "",
    title: "",
    description: "",
    price: undefined,
    discount: undefined,
    images: [],
  });
  const { category, title, description, price, discount, images } = formData;

  const [subCategories, setSubCategories] = useState<string[] | null>(null);
  const [sets, setSets] = useState<Set[] | null>(null);

  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const [selectedSet, setSelectedSet] = useState<string>("");

  const filterSetsFromCategory = (_cat: string) => {
    setSets(null);
    if (allSets !== null && allSets !== undefined) {
      let _c = _cat!.toLowerCase();
      let setInCategory = allSets?.filter(
        (set) =>
          (set.subcategory === null || set.subcategory === "") &&
          set.category === _c
      );

      if (setInCategory !== null && setInCategory !== undefined) {
        setSets(setInCategory);
      }
    } else {
      setSets(null);
    }
  };

  const filterSetsFromSubcategory = (subCategory: string) => {
    if (subCategory === "") {
      setSets(null);
    } else {
      if (allSets !== null && allSets !== undefined) {
        let setInCategory = allSets?.filter(
          (set) => set.subcategory === subCategory
        );

        if (setInCategory !== null && setInCategory !== undefined) {
          setSets(setInCategory);
        }
      } else {
        setSets(null);
      }
    }
  };

  const filterSubcategories = (cat: string) => {
    setSelectedSubCategory("");
    setSelectedSet("");
    setSubCategories(null);
    setSets(null);
    if (cat === "") {
      setSubCategories(null);
    } else {
      let _cat = cat.toLowerCase();
      setSubCategories(findSubcategories(_cat!));
    }
  };

  const { handleAddProduct } = useAdminModelController(
    productRepository,
    quotesRepository
  );

  const onAddProductCover = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      const resizeImage = (image: File) => {
        return new Promise<File>((resolve) => {
          resizeFile(image, (resizedImage: File) => {
            resolve(resizedImage);
          });
        });
      };

      resizeImage(file).then((resizedImage) => {
        setFormData((prevState) => ({
          ...prevState,
          images: [resizedImage, ...prevState.images],
        }));

        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target && event.target.result) {
            const preview = event.target.result.toString();
            setCoverImagePreview(preview);
          }
        };
        reader.readAsDataURL(resizedImage);
      });
    }
  };

  const onAddProductImages = (e: any) => {
    if (e.target.files) {
      const images: FileList = e.target.files;

      const resizeImage = (image: File) => {
        return new Promise<File>((resolve) => {
          resizeFile(image, (resizedImage: File) => {
            resolve(resizedImage);
          });
        });
      };

      const resizedImagesPromises: Promise<File>[] = [];

      for (let i = 0; i < images.length; i++) {
        resizedImagesPromises.push(resizeImage(images[i]));
      }

      Promise.all(resizedImagesPromises).then((resizedImages: File[]) => {
        setFormData((prevState) => ({
          ...prevState,
          images: [...prevState.images, ...resizedImages],
        }));

        const imagePreviewsPromises: Promise<string>[] = [];
        for (let i = 0; i < resizedImages.length; i++) {
          imagePreviewsPromises.push(convertToBase64(resizedImages[i]));
        }
        Promise.all(imagePreviewsPromises).then((previews: string[]) => {
          setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
        });
      });
    }
  };

  const onMutate = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let value: any = e.target.value;

    if (value === "true" || value === "false") {
      value = value === "true";
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  const handleCoverDelete = () => {
    setCoverImagePreview("");
    const updatedImages = [...images];
    updatedImages.splice(0, 1);
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const handleImageDelete = (index: number) => {
    const updatedPreviews = [...imagePreviews];
    const updatedImages = [...images];

    updatedPreviews.splice(index, 1);
    updatedImages.splice(index, 1);

    setImagePreviews(updatedPreviews);

    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages,
    }));
  };

  const addProductToDb = async () => {
    if (validFields()) {
      setShowLoading(true);

      try {
        const parsedPrice = parseFloat(
          formData.price!.toString().replace(",", ".")
        );

        const parsedDiscount =
          discount === undefined || discount.toString().trim() === ""
            ? 0
            : parseFloat(formData.discount!.toString().replace(",", "."));

        const updatedFormData = {
          ...formData,
          category: [formData.category, selectedSubCategory, selectedSet]
            .filter((part) => part !== "")
            .join("\\"),
          price: parsedPrice,
          discount: parsedDiscount,
        };

        const result = await handleAddProduct(
          updatedFormData,
          selectedSubCategory === "" || selectedSet === ""
        );

        if (result) {
          showSuccessToast("Il prodotto è stato aggiunto con successo!");
        } else {
          showErrorToast(
            "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
          );
        }
      } catch (error) {
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
        );
      } finally {
        setShowLoading(false);
      }

      clearFields();
      onConfirm();
    } else {
      showErrorToast("Completa tutti i campi");
    }
  };

  const clearFields = () => {
    setFormData({
      category: "",
      title: "",
      description: "",
      price: undefined,
      discount: undefined,
      images: [],
    });
    setCoverImagePreview("");
    setImagePreviews([]);
    setSelectedSubCategory("");
    setSelectedSet("");
  };

  const validFields = () => {
    let subCategoryValid =
      subCategories === null ||
      subCategories.length === 0 ||
      selectedSubCategory !== "";
    let setValid =
      sets === null ||
      sets.length === 0 ||
      selectedSet !== "" ||
      selectedSubCategory != "";

    return (
      category !== "" &&
      category !== undefined &&
      subCategoryValid &&
      setValid &&
      title !== "" &&
      title !== undefined &&
      description !== "" &&
      description !== undefined &&
      price !== 0 &&
      price !== undefined &&
      price !== null &&
      price.toString() !== "" &&
      images.length > 0 &&
      images !== undefined
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 py-4 sm:p-6 sm:pb-4">
                  <div className="mt-3 text-center  sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center font-semibold leading-6 text-gray-900"
                    >
                      Aggiungi prodotto
                    </Dialog.Title>
                    <div className="mt-2">
                      <form>
                        <div className="pb-8">
                          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label
                                htmlFor="title"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Titolo
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  name="title"
                                  id="title"
                                  value={title}
                                  onChange={onMutate}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Descrizione
                              </label>
                              <div className="mt-2">
                                <textarea
                                  name="description"
                                  rows={3}
                                  id="description"
                                  value={description}
                                  onChange={onMutate}
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="price"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Prezzo
                              </label>
                              <div className="mt-2">
                                <input
                                  type="number"
                                  name="price"
                                  id="price"
                                  placeholder="0€"
                                  value={price}
                                  onChange={onMutate}
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-3">
                              <label
                                htmlFor="discount"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Sconto %
                              </label>
                              <div className="mt-2">
                                <input
                                  type="number"
                                  name="discount"
                                  id="discount"
                                  placeholder="0%"
                                  value={discount}
                                  onChange={onMutate}
                                  autoComplete="given-name"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="sm:col-span-2">
                              <label
                                htmlFor="category"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Categoria
                              </label>
                              <div className="mt-2">
                                <select
                                  id="category"
                                  value={category}
                                  onChange={(e) => {
                                    onMutate(e);
                                    filterSubcategories(e.target.value);
                                    filterSetsFromCategory(e.target.value);
                                  }}
                                  name="category"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                                >
                                  <option value="">
                                    Seleziona una categoria
                                  </option>
                                  {categories.map((category, index) => (
                                    <option key={index}>{category}</option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {subCategories && subCategories.length > 0 && (
                              <div className="sm:col-span-2">
                                <label
                                  htmlFor="category"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Sottocategoria
                                </label>
                                <div className="mt-2">
                                  <select
                                    id="subcategory"
                                    value={selectedSubCategory}
                                    onChange={(e) => {
                                      setSelectedSubCategory(e.target.value);
                                      filterSetsFromSubcategory(e.target.value);
                                    }}
                                    disabled={
                                      subCategories === null ||
                                      subCategories.length === 0
                                    }
                                    name="subcategory"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                                  >
                                    <option value="">
                                      Seleziona una sottocategoria
                                    </option>
                                    {subCategories.map((subCategory, index) => (
                                      <option key={index}>{subCategory}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}

                            {sets && sets.length > 0 && (
                              <div className="sm:col-span-2">
                                <label
                                  htmlFor="selectedSet"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Set
                                </label>
                                <div className="mt-2">
                                  <select
                                    id="selectedSet"
                                    value={selectedSet}
                                    disabled={
                                      sets === null || sets.length === 0
                                    }
                                    onChange={(e) => {
                                      setSelectedSet(e.target.value);
                                    }}
                                    name="selectedSet"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                                  >
                                    <option value="">Nessun set</option>
                                    {sets?.map((set, index) => (
                                      <option key={index}>{set.name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}

                            {coverImagePreview === "" && (
                              <div className="col-span-full">
                                <label
                                  htmlFor="cover-photo"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Immagine di copertina
                                </label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2">
                                  <div className="text-center">
                                    <PhotoIcon
                                      className="mx-auto h-12 w-12 text-gray-300"
                                      aria-hidden="true"
                                    />
                                    <div className="mt-2 flex text-sm leading-6 text-gray-600">
                                      <label
                                        htmlFor="cover-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-palette-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-palette-primary focus-within:ring-offset-2 hover:bg-palette-light"
                                      >
                                        <span>Carica la cover</span>
                                        <input
                                          type="file"
                                          id="cover-upload"
                                          name="cover-upload"
                                          className="sr-only"
                                          accept=".jpg,.png,.jpeg"
                                          onChange={onAddProductCover}
                                        />
                                      </label>
                                      <p className="pl-1">o trascinala qua</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {coverImagePreview !== "" && (
                              <div className="col-span-full">
                                <label
                                  htmlFor="cover-photo"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Immagine di copertina
                                </label>
                                <div className="mt-2 rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                  <div className="relative flex justify-center items-center">
                                    <img
                                      src={coverImagePreview}
                                      alt="Immagine cover"
                                      className="max-h-60 object-contain"
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                                      <div className="bg-gray-600 bg-opacity-50 absolute inset-0 flex items-center justify-center">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6 text-white cursor-pointer"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          onClick={handleCoverDelete}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {imagePreviews.length === 0 && (
                              <div className="col-span-full">
                                <label
                                  htmlFor="cover-photo"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Immagini dei prodotti
                                </label>
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                  <div className="text-center">
                                    <PhotoIcon
                                      className="mx-auto h-12 w-12 text-gray-300"
                                      aria-hidden="true"
                                    />
                                    <div className="mt-2 flex text-sm leading-6 text-gray-600">
                                      <label
                                        htmlFor="image-upload"
                                        className="relative cursor-pointer rounded-md bg-white font-semibold text-palette-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-palette-primary focus-within:ring-offset-2 hover:text-palette-dark"
                                      >
                                        <span>Carica le foto</span>
                                        <input
                                          id="image-upload"
                                          onChange={onAddProductImages}
                                          type="file"
                                          accept=".jpg,.png,.jpeg"
                                          className="sr-only"
                                          required
                                          multiple
                                          max="10"
                                        />
                                      </label>
                                      <p className="pl-1">o trascinale qua</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {imagePreviews.length > 0 && (
                              <div className="col-span-full">
                                <label
                                  htmlFor="cover-photo"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Immagini prodotti
                                </label>
                                <div className="mt-2 grid grid-cols-5 gap-3 rounded-lg border border-dashed border-gray-900/25 px-6 py-6">
                                  {imagePreviews.map((preview, index) => (
                                    <div
                                      key={index}
                                      className="relative flex items-center justify-center"
                                    >
                                      <img
                                        src={preview}
                                        alt={`Immagine ${index}`}
                                        className="max-h-20 object-contain"
                                      />
                                      <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                                        <div className="bg-gray-600 bg-opacity-50 absolute inset-0 flex items-center justify-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-white cursor-pointer"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            onClick={() =>
                                              handleImageDelete(index)
                                            }
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={2}
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="text-sm text-center my-auto leading-6 text-gray-600">
                                    <label
                                      htmlFor="image-upload"
                                      className="relative cursor-pointer font-semibold text-palette-primary hover:text-palette-dark"
                                    >
                                      <span>Carica altre foto</span>
                                      <input
                                        id="image-upload"
                                        onChange={onAddProductImages}
                                        type="file"
                                        accept=".jpg,.png,.jpeg"
                                        className="sr-only"
                                        required
                                        multiple
                                        max="10"
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {showLoading ? (
                  <div className="w-full bg-gray-50 rounded px-4 py-4 ">
                    <div
                      style={{ width: "100%" }}
                      className="absolute top-0 rounded-full shim-light"
                    >
                      <p className="p-1 text-base text-center font-bold leading-6 text-gray-900">
                        Caricamento...
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-palette-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark sm:ml-3 sm:w-auto"
                      onClick={addProductToDb}
                    >
                      Aggiungi
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-700 hover:text-white sm:mt-0 sm:w-auto "
                      onClick={() => {
                        setFormData({
                          category: "",
                          title: "",
                          description: "",
                          price: undefined,
                          discount: undefined,
                          images: [],
                        });
                        setCoverImagePreview("");
                        setImagePreviews([]);
                        onClose();
                      }}
                    >
                      Cancella
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
