import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useUpdateCartQuantityContext } from "../../../context/cart";
import { ProductCart } from "../../../domain/models/ProductCart";
import { Set } from "../../../domain/models/Set";
import { useClientModelController } from "../../hook/useClientModelController";
import { ProductsRepository } from "../../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import { getCategoryFromFullPath } from "../../../utils/productUtils";

export function CartPanelComponent({
  isOpen,
  products,
  onCartClosed,
  productsRepository,
  quotesRepository,
}: {
  isOpen: boolean;
  products: ProductCart[];
  onCartClosed: () => void;
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const [open, setOpen] = useState(isOpen);
  const updateCartQuantity = useUpdateCartQuantityContext();
  const [sets, setSets] = useState<Set[] | null>(null);
  const { handleGetAllSets } = useClientModelController(
    productsRepository,
    quotesRepository
  );

  useEffect(() => {
    if (!sets) {
      getSets();
    }
  }, []);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const getSets = async () => {
    const setsResult = await handleGetAllSets();
    setSets(setsResult);
  };

  const calculateTotal = (): string => {
    let total = 0;

    products.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });

    return total.toFixed(2);
  };

  const getSetFromProduct = (product: ProductCart): Set | null => {
    const set = sets?.filter(
      (set) =>
        set.name.toLowerCase() === getCategoryFromFullPath(product.category)
    );
    return set ? set[0] : null;
  };

  const groupProductsBySet = (products: ProductCart[]) => {
    const grouped: { [key: string]: ProductCart[] } = {};

    products.forEach((product) => {
      const set = getSetFromProduct(product);
      if (set) {
        if (!grouped[set.name]) {
          grouped[set.name] = [];
        }
        grouped[set.name].push(product);
      } else {
        if (!grouped[product.id]) {
          grouped[product.id] = [];
        }
        grouped[product.id].push(product);
      }
    });

    return grouped;
  };

  const groupedProducts = groupProductsBySet(products);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          onCartClosed();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Carrello
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative -m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={() => {
                              setOpen(false);
                              onCartClosed();
                            }}
                          >
                            <span className="absolute -inset-0.5" />
                            <span className="sr-only">Chiudi</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          <ul
                            role="list"
                            className="-my-6 divide-y divide-gray-200"
                          >
                            {Object.entries(groupedProducts).map(
                              ([groupKey, groupProducts]) => {
                                const set = sets?.find(
                                  (set) => set.name === groupKey
                                );
                                return (
                                  <li
                                    key={groupKey}
                                    className="flex flex-col py-6"
                                  >
                                    <div className="flex items-start">
                                      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                        <img
                                          src={
                                            set
                                              ? set.image
                                              : groupProducts[0].image
                                          }
                                          alt={
                                            set
                                              ? set.image
                                              : groupProducts[0].image
                                          }
                                          className="h-full w-full object-contain object-center"
                                        />
                                      </div>
                                      <div className="ml-4 flex flex-1 flex-col">
                                        <div>
                                          <div className="flex justify-between text-base font-medium text-gray-900">
                                            <h3>
                                              <a
                                                href={
                                                  set
                                                    ? set.subcategory
                                                      ? `/catalog/${set.category}/${set.subcategory}/${set.name}`
                                                      : `/catalog/${set.category}/${set.name}`
                                                    : `/catalog/${groupProducts[0].id}`
                                                }
                                              >
                                                {set
                                                  ? set.name
                                                  : groupProducts[0].title}
                                              </a>
                                            </h3>
                                            <p className="ml-4">
                                              {groupProducts
                                                .reduce(
                                                  (total, product) =>
                                                    total +
                                                    product.price *
                                                      product.quantity,
                                                  0
                                                )
                                                .toFixed(2)
                                                .replace(".", ",")}
                                              €
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <ul className="mt-2 space-y-2">
                                      {groupProducts.map((product) => (
                                        <li
                                          key={product.id}
                                          className="flex items-center justify-between text-sm"
                                        >
                                          <div className="flex w-full justify-between pr-6">
                                            <p className="text-gray-500">
                                              {product.title}
                                            </p>
                                            <p className="ml-4 text-gray-500">
                                              Qtà {product.quantity}
                                            </p>
                                          </div>
                                          <button
                                            onClick={() =>
                                              updateCartQuantity(product.id, 0)
                                            }
                                            type="button"
                                            className="font-medium text-palette-primary hover:text-palette-dark"
                                          >
                                            Rimuovi
                                          </button>
                                        </li>
                                      ))}
                                    </ul>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <p>Totale</p>
                        <p>{calculateTotal().replace(".", ",")}€</p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Il costo totale indicato non include le spese di
                        spedizione ed è solo un valore indicativo che può
                        variare in fase di creazione del preventivo.
                      </p>
                      <div className="mt-6">
                        <a
                          href="/checkout"
                          className="flex items-center justify-center rounded-md border border-transparent bg-palette-primary hover:bg-palette-dark px-6 py-3 text-base font-medium text-white shadow-sm "
                        >
                          Checkout
                        </a>
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          o{" "}
                          <button
                            type="button"
                            className="font-medium text-palette-light hover:text-palette-dark"
                            onClick={() => {
                              setOpen(false);
                              onCartClosed();
                            }}
                          >
                            continua ad acquistare
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
