type StringMap = Record<string, string>;

export const navigation = {
  pages: [
    { name: "Sedie", href: "/catalog/Sedie", subcategories: null },
    { name: "Tavoli", href: "/catalog/Tavoli", subcategories: null },
    {
      name: "Piatti e sottopiatti",
      href: "/catalog/Piatti e sottopiatti",
      subcategories: null,
    },
    {
      name: "Posate",
      href: "/catalog/Posate",
      subcategories: null,
    },
    {
      name: "Bicchieri",
      href: "/catalog/Bicchieri",
      subcategories: null,
    },
    {
      name: "Arredo giardino",
      href: "/catalog/Arredo giardino",
      subcategories: null,
    },
    {
      name: "Tovagliato",
      href: "/catalog/Tovagliato",
      subcategories: null,
    },
  ],
};

export const categories: string[] = [
  "Sedie",
  "Tavoli",
  "Piatti e sottopiatti",
  "Posate",
  "Bicchieri",
  "Arredo giardino",
  "Tovagliato",
];

export const findSubcategories = (categoryName: string): string[] | null => {
  for (const page of navigation.pages) {
    if (page.name.toLowerCase() === categoryName.toLowerCase()) {
      return page.subcategories || null;
    }
  }
  return null;
};

export const getCategoryFromFullPath = (path: string): string => {
  if (!path) return "";
  const productCategoryParts = path.split("\\");
  const productCategoryLastPart: string = productCategoryParts
    .pop()!
    .toLowerCase();
  return productCategoryLastPart;
};
