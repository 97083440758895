import { Set, ProductData, QuoteRequest } from "../../domain/models";
import { ProductsRepository } from "../../domain/repository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { createPendingQuote } from "../../domain/usecase/CreatePendingQuoteUseCase";
import { getAllProducts } from "../../domain/usecase/GetAllProductsUseCase";
import { getSets } from "../../domain/usecase/GetSetsUseCase";

export function useClientModelController(
  productsRepository: ProductsRepository,
  quotesRepository: QuotesRepository
) {
  const handleGetAllSets = async (): Promise<Set[] | null> => {
    return await getSets(productsRepository);
  };

  const handleGetAllProducts = async (): Promise<ProductData[]> => {
    return await getAllProducts(productsRepository);
  };

  const handleCreatePendingQuote = async (
    quoteRequest: QuoteRequest
  ): Promise<boolean> => {
    return await createPendingQuote(quoteRequest, quotesRepository);
  };
  return {
    handleGetAllSets,
    handleGetAllProducts,
    handleCreatePendingQuote,
  };
}
