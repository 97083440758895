import logo from "../../../assets/images/logo-dap-rent.png";

export function FooterComponent() {
  return (
    <div id="contact" className="bg-white pt-24 ">
      <div className="py-10">
        <img src={logo} className="w-24 mx-auto" alt="logo" />
        <p className="text-center text-sm leading-6 text-slate-500 mt-6">
          © 2024 Dap Rent All rights reserved.
        </p>
        <div className="mt-10 flex w-full px-4 items-center justify-center text-center space-x-4 text-sm font-semibold leading-6 text-slate-700">
          <a href="https://daprent.it/privacy-policy">
            Informativa sulla privacy
          </a>
          <div className="h-4 w-px bg-slate-500/20"></div>
          <a href="https://daprent.it/terms-conditions">Termini e condizioni</a>
          <div className="hidden sm:block h-4 w-px bg-slate-500/20"></div>
          <a href="https://gowebb.it" className="hidden sm:block">
            Made by GoWebb
          </a>
        </div>
        <div className="sm:hidden mt-4 flex w-full px-4 items-center justify-center text-center space-x-4 text-sm font-semibold leading-6 text-slate-700">
          <a href="https://gowebb.it" className="sm:hidden">
            Made by GoWebb
          </a>
        </div>
      </div>
    </div>
  );
}
