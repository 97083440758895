import { Set } from "../models/Set";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function getSets(
  repository: ProductsRepository
): Promise<Set[] | null> {
  const result = await repository.getSets();
  if (result instanceof Success) {
    return result.value;
  } else {
    return null;
  }
}
