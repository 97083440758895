import { FirebaseEmail } from "../models/FirebaseEmail";
import { NewProcessedQuote } from "../models/NewProcessedQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function uploadPDFQuote(
  clientEmail: string,
  pdf: File,
  fileName: string,
  repository: QuotesRepository
): Promise<string> {
  const result = await repository.uploadPDFQuote(clientEmail, pdf, fileName);
  if (result instanceof Success) {
    return result.value;
  } else {
    return "";
  }
}
