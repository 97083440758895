import { createContext, useContext, useState, useEffect } from "react";
import { setLocalData, saveLocalData } from "../utils/localStorageUtils";
import { ProductCart } from "../domain/models/ProductCart";

const CartContext = createContext<ProductCart[]>([]);
const AddToCartContext = createContext((newItem: ProductCart) => {});
const UpdateCartQuantityContext = createContext(
  (id: string, quantity: number) => {}
);

export function useCartContext() {
  return useContext(CartContext);
}

export function useAddToCartContext() {
  return useContext(AddToCartContext);
}

export function useUpdateCartQuantityContext() {
  return useContext(UpdateCartQuantityContext);
}

export function CartProvider({ children }: { children: React.ReactNode }) {
  const [cart, setCart] = useState<ProductCart[]>([]);

  useEffect(() => {
    setLocalData(setCart);
  }, []);

  useEffect(() => {
    // do this to make sure multiple tabs are always in sync
    const onReceiveMessage = (e: any) => {
      setLocalData(setCart);
    };

    window.addEventListener("storage", onReceiveMessage);
    return () => {
      window.removeEventListener("storage", onReceiveMessage);
    };
  }, []);

  async function addToCart(newItem: ProductCart) {
    // empty cart
    if (cart.length === 0) {
      setCart([...cart, newItem]);

      saveLocalData(newItem);
    } else {
      let newCart = [...cart];
      let itemAdded = false;
      // loop through all cart items to check if variant
      // already exists and update quantity
      newCart.map((item: ProductCart) => {
        if (item.id === newItem.id) {
          item.quantity += newItem.quantity;
          itemAdded = true;
        }
      });

      let newCartWithItem = [...newCart];
      if (itemAdded) {
      } else {
        // if its a new item than add it to the end
        newCartWithItem = [...newCart, newItem];
      }

      setCart(newCartWithItem);

      saveLocalData(newCartWithItem);
    }
  }

  async function updateCartItemQuantity(id: string, quantity: number) {
    let newQuantity = Math.floor(quantity);
    if (quantity < 0) {
      newQuantity = 0;
    }
    let newCart = [...cart];
    newCart.forEach((item) => {
      if (item.id === id) {
        item.quantity = newQuantity;
      }
    });

    // take out zeroes items
    newCart = newCart.filter((i) => i.quantity !== 0);
    setCart(newCart);

    saveLocalData(newCart);
  }

  return (
    <CartContext.Provider value={cart}>
      <AddToCartContext.Provider value={addToCart}>
        <UpdateCartQuantityContext.Provider value={updateCartItemQuantity}>
          {children}
        </UpdateCartQuantityContext.Provider>
      </AddToCartContext.Provider>
    </CartContext.Provider>
  );
}
