import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  PopoverGroup,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  ShoppingBagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useCartContext } from "../../../context/cart";
import { CartPanelComponent } from "./CartPanelComponent";
import { ProductCart } from "../../../domain/models/ProductCart";
import logo from "../../../assets/images/logo-dap-rent.png";
import { useClientModelController } from "../../hook/useClientModelController";
import { ProductsRepository } from "../../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import { navigation } from "../../../utils/productUtils";

export function HeaderComponent({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const [open, setOpen] = useState(false);
  const cart = useCartContext();
  const [cartProducts, setCartProducts] = useState<ProductCart[]>([]);
  const [cartCounter, setCartCounter] = useState<number>(0);
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    let numItems = 0;
    let cartItems: ProductCart[] = [];
    cart.forEach((item) => {
      cartItems.push(item);
      numItems += item?.quantity ?? 0;
    });
    setCartProducts(cartItems);
    setCartCounter(numItems);
  }, [cart]);

  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pb-2 pt-5">
                  <button
                    type="button"
                    className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Chiudi menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                  {navigation.pages.map((page) => (
                    <div key={page.name} className="flow-root">
                      <a
                        href={page.href}
                        className="-m-2 block p-2 font-medium text-gray-900"
                      >
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      <header className="relative bg-white">
        <nav aria-label="Top" className="mx-auto w-full px-4 sm:px-6 lg:px-8">
          <div className="border-b border-gray-200">
            <div className="flex h-16 items-center">
              <button
                type="button"
                className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
                onClick={() => setOpen(true)}
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Apri menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <a href="/">
                  <span className="sr-only">Your Company</span>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </a>
              </div>

              {/* Flyout menus */}
              <PopoverGroup className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </PopoverGroup>

              <div className="ml-auto flex items-center">
                {/* Cart */}
                <div className="ml-4 flow-root lg:ml-6">
                  <div
                    onClick={() => setIsCartOpen(!isCartOpen)}
                    className="group -m-2 flex items-center p-2"
                  >
                    <ShoppingBagIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">
                      {cartCounter}
                    </span>
                    <span className="sr-only">elementi nel carrello</span>
                  </div>
                </div>
              </div>

              <CartPanelComponent
                products={cartProducts}
                isOpen={isCartOpen}
                onCartClosed={() => {
                  setIsCartOpen(false);
                }}
                productsRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
