import Resizer from "react-image-file-resizer";

export const resizeFile = (file: File, callback: (image: File) => void) =>
  Resizer.imageFileResizer(
    file,
    800,
    800,
    "PNG",
    80,
    0,
    (output: any) => {
      callback(output);
    },
    "file"
  );

export const convertToBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result?.toString() ?? "";
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};
