const stats = [
  { id: 1, name: "Mezzi di proprietà", value: "15" },
  { id: 2, name: "", value: "" },
  { id: 3, name: "Dipendenti", value: "85" },
  { id: 4, name: "", value: "" },
  { id: 5, name: "Servizi in un anno", value: "350" },
  { id: 6, name: "", value: "" },
  { id: 7, name: "Matrimoni fino ad oggi", value: "1324" },
  { id: 8, name: "", value: "" },
  { id: 9, name: "Coperti in un unico evento", value: "2500+" },
];

export function StatsComponent() {
  return (
    <div className="bg-palette-dark py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-5">
          {stats.map((stat) => (
            <div
              key={stat.id}
              className="mx-auto flex max-w-xs flex-col gap-y-4 text-center justify-center"
            >
              <dt className="text-base leading-7 text-gray-200">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
