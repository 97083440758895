import { ProductData } from "../models/ProductData";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function deleteProduct(
  product: ProductData,
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.deleteProduct(product);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
