import abstract_circles from "../../../assets/images/abstract_circles.png";
import abstract_arrow from "../../../assets/images/abstract_arrow.png";
import abstract_arrow_light from "../../../assets/images/abstract_arrow_light.png";
import abstract_heart from "../../../assets/images/abstract_heart.png";
import abstract_line from "../../../assets/images/abstract_line.png";

const decorativeImages = [
  abstract_circles,
  abstract_arrow,
  abstract_arrow_light,
  abstract_heart,
  abstract_line,
];

const shuffleArray = (array: any) => {
  let shuffled = array.slice();
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

function SubCategoryItem({
  categoryName,
  onClick,
}: {
  categoryName: string;
  onClick: (subCategory: string) => void;
}) {
  const [topLeftImage, bottomLeftImage, bottomRightImage, topRightImage] =
    shuffleArray(decorativeImages).slice(0, 4);

  return (
    <div className="relative">
      <div
        key={categoryName}
        className="group relative h-[200px] rounded-lg bg-white border-2 border-palette-primary text-center flex items-center justify-center text-2xl font-bold cursor-pointer"
        onClick={() => onClick(categoryName)}
      >
        {categoryName}
      </div>
      <img
        src={topLeftImage}
        alt="Decorative"
        className="absolute top-2 left-2 w-16 h-16"
      />
      <img
        src={bottomLeftImage}
        alt="Decorative"
        className="absolute bottom-2 left-2 w-16 h-16"
      />
      <img
        src={bottomRightImage}
        alt="Decorative"
        className="absolute bottom-2 right-2 w-16 h-16"
      />
      <img
        src={topRightImage}
        alt="Decorative"
        className="absolute top-2 right-2 w-16 h-16"
      />
    </div>
  );
}

export default SubCategoryItem;
