import { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CircularLoaderComponent } from "../CircularLoaderComponent";
import { ProductData } from "../../../domain/models";

export function ProductImageComponent({ product }: { product: ProductData }) {
  const [mainImg, setMainImg] = useState<string>("");
  const ref = useRef<HTMLDivElement | null>(null);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  function scroll(scrollOffset: number) {
    if (ref !== null && ref.current !== null) {
      ref.current.scrollLeft += scrollOffset;
    }
  }

  useEffect(() => {
    if (product.images.length > 0) {
      setMainImg(product.images[0]);
      setShowLoader(false);
    }
  }, [product.images]);

  if (showLoader) {
    return (
      <div className="flex w-full h-[500px] mx-auto items-center justify-center">
        <CircularLoaderComponent />
      </div>
    );
  }
  return (
    <div className="w-full md:w-1/2 max-w-md bg-white rounded-xl shadow-lg">
      <div className="relative h-96">
        <img
          src={mainImg}
          alt="cover"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
          className="transform duration-500 ease-in-out hover:scale-105"
        />
      </div>
      <div className="mt-4 relative flex">
        <button
          aria-label="left-scroll"
          className="h-32 bg-neutral-100 hover:bg-white absolute left-0 z-10 opacity-75"
          onClick={() => scroll(-300)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="w-3 mx-1" />
        </button>
        <div
          ref={ref}
          style={{ scrollBehavior: "smooth" }}
          className="flex space-x-1 w-full overflow-auto"
        >
          {product.images.map((imgItem: string, index: number) => (
            <button
              key={index}
              className="relative w-40 h-32 flex-shrink-0 rounded-sm "
              onClick={() => setMainImg(imgItem)}
            >
              <img
                src={imgItem}
                alt="other images"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                className=""
              />
            </button>
          ))}
        </div>
        <button
          aria-label="right-scroll"
          className="h-32 bg-neutral-100 hover:bg-white absolute right-0 z-10 opacity-75"
          onClick={() => scroll(300)}
        >
          <FontAwesomeIcon icon={faArrowRight} className="w-3 mx-1" />
        </button>
      </div>
    </div>
  );
}
