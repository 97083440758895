import hero_image from "../../../assets/images/hero_image.jpeg";
import abstract_circles from "../../../assets/images/abstract_circles.png";
import abstract_arrow from "../../../assets/images/abstract_arrow.png";
import abstract_arrow_light from "../../../assets/images/abstract_arrow_light.png";
import abstract_heart from "../../../assets/images/abstract_heart.png";
import abstract_line from "../../../assets/images/abstract_line.png";
import hero_logo from "../../../assets/images/hero_logo.png";
export function HeroComponent() {
  return (
    <div className="">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-cols-2 gap-y-16 justify-items-center lg:justify-items-end items-center text-center lg:text-left">
        <div className="relative mt-8 w-full h-screen">
          <img
            className="absolute top-40 sm:top-1/4 left-2/3 sm:left-1/2 right-0 h-[100px] floating"
            src={abstract_circles}
            alt="Hero decoration"
          />
          <img
            className="absolute top-0 left-0 bottom-0 h-[100px] floating-reverse"
            src={abstract_arrow}
            alt="Hero decoration"
          />
          <img
            className="absolute left-0 bottom-20 h-[100px] floating"
            src={abstract_arrow_light}
            alt="Hero decoration"
          />
          <img
            className="absolute top-2/4 left-4/5 right-0 h-[100px] floating-reverse"
            src={abstract_heart}
            alt="Hero decoration"
          />
          <img
            className="absolute top-3/4 sm:top-20 left-4/5 right-0 h-[100px] floating"
            src={abstract_line}
            alt="Hero decoration"
          />
          <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center lg:items-start justify-center p-8">
            <img className="mx-auto h-[300px]" src={hero_logo} />
            <p className="mt-8 text-xl text-gray-800">
              Maestri nel settore del catering e del banqueting, grazie alla
              nostra esperienza ti aiutiamo a creare l’evento dei tuoi sogni.
            </p>
            <a
              href="#categories"
              className="inline-block mx-auto mt-8 w-fit rounded-md border border-transparent bg-palette-primary px-8 py-3 text-center font-medium text-white hover:bg-palette-dark"
            >
              Esplora il catalogo
            </a>
          </div>
        </div>

        <div className="pt-8">
          <img
            className="h-auto sm:h-screen rounded-md"
            src={hero_image}
            alt="Hero image"
          />
        </div>
      </div>
    </div>
  );
}
