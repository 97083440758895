import { useEffect, useState } from "react";
import { ProductData } from "../../../domain/models/ProductData";
import { NewProductData, Set } from "../../../domain/models";
import { categories, findSubcategories } from "../../../utils/productUtils";

const categoryList: string[] = categories.map((c) => c.toLowerCase());

export function EditProductInfoComponent({
  product,
  allSets,
  onFormDataChange,
  onCategoryChanged,
  onSubcategoryChanged,
}: {
  product: ProductData;
  allSets: Set[] | null;
  onFormDataChange: (formData: NewProductData) => void;
  onCategoryChanged: (valid: boolean) => void;
  onSubcategoryChanged: (valid: boolean) => void;
}) {
  const [formData, setFormData] = useState<NewProductData>({
    category: product.category,
    title: product.title,
    description: product.description,
    price: parseFloat(product.price.toString().replace(",", ".")),
    discount: parseFloat(product.discount.toString().replace(",", ".")),
    images: [],
  });

  useEffect(() => {
    const parts = product.category.split("\\");
    const category = parts[0] || "";
    const subCategory = parts[1] || "";
    const set = parts[2] || "";
    filterSubcategories(category);
    if (subCategory !== "") {
      filterSetsFromSubcategory(subCategory);
    } else {
      filterSetsFromCategory(category);
    }
    setSelectedCategory(category);
    setSelectedSubCategory(subCategory);
    setSelectedSet(set);
  }, []);

  const [subCategories, setSubCategories] = useState<string[] | null>(null);
  const [sets, setSets] = useState<Set[] | null>(null);

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const [selectedSet, setSelectedSet] = useState<string>("");

  useEffect(() => {
    const updatedFormData: NewProductData = {
      ...formData,
      category: [selectedCategory, selectedSubCategory, selectedSet]
        .filter((part) => part !== "")
        .join("\\"),
    };
    onFormDataChange({
      ...updatedFormData,
    });
  }, [selectedCategory, selectedSubCategory, selectedSet, formData]);

  useEffect(() => {
    onCategoryChanged(selectedCategory !== "");

    if (subCategories === null || subCategories.length === 0) {
      onSubcategoryChanged(true);
    } else {
      if (!selectedSubCategory || selectedSubCategory === "") {
        onSubcategoryChanged(false);
      } else {
        onSubcategoryChanged(true);
      }
    }
  }, [selectedCategory, selectedSubCategory, subCategories]);

  const filterSetsFromCategory = (_cat: string) => {
    setSets(null);
    if (allSets !== null && allSets !== undefined) {
      let _c = _cat!.toLowerCase();
      let setInCategory = allSets?.filter(
        (set) =>
          (set.subcategory === null || set.subcategory === "") &&
          set.category.toLowerCase() === _c
      );

      if (setInCategory !== null && setInCategory !== undefined) {
        setSets(setInCategory);
      }
    } else {
      setSets(null);
    }
  };

  const filterSetsFromSubcategory = (subCategory: string) => {
    if (subCategory === "") {
      setSets(null);
    } else {
      if (allSets !== null && allSets !== undefined) {
        let setInCategory = allSets?.filter(
          (set) => set.subcategory?.toLowerCase() === subCategory.toLowerCase()
        );

        if (setInCategory !== null && setInCategory !== undefined) {
          setSets(setInCategory);
        }
      } else {
        setSets(null);
      }
    }
  };

  const filterSubcategories = (cat: string) => {
    setSubCategories(null);
    setSets(null);
    if (cat === "") {
      setSubCategories(null);
    } else {
      let _cat = cat.toLowerCase();
      setSubCategories(findSubcategories(_cat!));
    }
  };

  const onMutate = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    let value: any = e.target.value;

    if (value === "true" || value === "false") {
      value = value === "true";
    }

    if (e.target.id === "price" || e.target.id === "discount") {
      if (value === null) {
        value = 0;
      }
      value = parseFloat(value.toString().replace(",", "."));
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  return (
    <>
      <form>
        <div className="pb-8">
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label
                htmlFor="title"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Titolo
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="title"
                  id="title"
                  value={formData.title}
                  onChange={onMutate}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="col-span-full">
              <label
                htmlFor="description"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Descrizione
              </label>
              <div className="mt-2">
                <textarea
                  name="description"
                  rows={3}
                  id="description"
                  value={formData.description}
                  onChange={onMutate}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="category"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Categoria
              </label>
              <div className="mt-2">
                <select
                  id="category"
                  value={selectedCategory}
                  onChange={(e) => {
                    onMutate(e);
                    setSelectedCategory(e.target.value);
                    setSelectedSubCategory("");
                    setSelectedSet("");
                    filterSubcategories(e.target.value);
                    filterSetsFromCategory(e.target.value);
                  }}
                  name="category"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                >
                  <option value="">Seleziona una categoria</option>
                  {categoryList.map((category, index) => (
                    <option key={index}>{category}</option>
                  ))}
                </select>
              </div>
            </div>

            {subCategories && subCategories.length > 0 && (
              <div className="sm:col-span-3">
                <label
                  htmlFor="category"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Sottocategoria
                </label>
                <div className="mt-2">
                  <select
                    id="subcategory"
                    value={selectedSubCategory}
                    onChange={(e) => {
                      onMutate(e);
                      setSelectedSubCategory(e.target.value);
                      filterSetsFromSubcategory(e.target.value);
                    }}
                    disabled={
                      subCategories === null || subCategories.length === 0
                    }
                    name="subcategory"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                  >
                    <option value="">Seleziona una sottocategoria</option>
                    {subCategories?.map((subCategory, index) => (
                      <option key={index}>{subCategory.toLowerCase()}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            {sets && sets.length > 0 && (
              <div className="sm:col-span-3">
                <label
                  htmlFor="selectedSet"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Set
                </label>
                <div className="mt-2">
                  <select
                    id="selectedSet"
                    value={selectedSet}
                    disabled={sets === null || sets.length === 0}
                    onChange={(e) => {
                      onMutate(e);
                      setSelectedSet(e.target.value);
                    }}
                    name="selectedSet"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-palette-dark  sm:text-sm sm:leading-6"
                  >
                    <option value="">Nessun set</option>
                    {sets?.map((set, index) => (
                      <option key={index}>{set.name.toLowerCase()}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="sm:col-span-3">
              <label
                htmlFor="price"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Prezzo
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="price"
                  id="price"
                  value={formData.price}
                  onChange={onMutate}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="discount"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Sconto
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  name="discount"
                  id="discount"
                  value={formData.discount}
                  onChange={onMutate}
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
