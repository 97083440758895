import chair_icon from "../../../assets/images/chair.png";
import table_icon from "../../../assets/images/table.png";
import mise_en_place_icon from "../../../assets/images/mise_en_place.png";
import garden_icon from "../../../assets/images/garden.png";
import cutlery from "../../../assets/images/cutlery.png";
import glass from "../../../assets/images/glass.png";
import tablecloth from "../../../assets/images/tablecloth.png";

const categories = [
  {
    name: "Sedie",
    imageSrc: chair_icon,
    imageAlt: "Sedie",
    href: "/catalog/Sedie",
  },
  {
    name: "Tavoli",
    imageSrc: table_icon,
    imageAlt: "Tavoli",
    href: "/catalog/Tavoli",
  },
  {
    name: "Piatti e sottopiatti",
    imageSrc: mise_en_place_icon,
    imageAlt: "Piatti e sottopiatti",
    href: "/catalog/Piatti e sottopiatti",
  },
  {
    name: "Posate",
    imageSrc: cutlery,
    imageAlt: "Posate",
    href: "/catalog/Posate",
  },
  {
    name: "Bicchieri",
    imageSrc: glass,
    imageAlt: "Bicchieri",
    href: "/catalog/Bicchieri",
  },
  {
    name: "Arredo giardino",
    imageSrc: garden_icon,
    imageAlt: "Arredo giardino",
    href: "/catalog/Arredo Giardino",
  },
  {
    name: "Tovagliato",
    imageSrc: tablecloth,
    imageAlt: "Tovagliato",
    href: "/catalog/Tovagliato",
  },
];

export function CategoriesComponent() {
  return (
    <div id="categories" className="bg-palette-dark my-24 sm:my-36 rounded-lg">
      <div className="mx-auto max-w-none px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-12 lg:max-w-none">
          {/* <h2 className="text-3xl font-bold text-white text-center">
            Categorie
          </h2> */}

          <div className="mt-12 gap-y-24 grid grid-cols-2 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-12 lg:space-y-0">
            {categories.map((category) => (
              <a
                key={category.name}
                className="relative mx-auto text-center px-4 sm:px-8 lg:px-32"
                href={category.href}
              >
                <div className="mx-auto my-auto relative p-12  rounded-lg bg-palette-light hover:opacity-75">
                  <img
                    src={category.imageSrc}
                    alt={category.imageAlt}
                    className="w-full h-full"
                  />
                </div>

                <p className="mt-4 text-base font-semibold text-white">
                  {category.name}
                </p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
