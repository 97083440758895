import {
  Set,
  NewProductData,
  PendingQuote,
  ProcessedQuote,
  ProductData,
} from "../../domain/models";
import { ProductsRepository } from "../../domain/repository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { addProduct } from "../../domain/usecase/AddProductUseCase";
import { deleteProduct } from "../../domain/usecase/DeleteProductUseCase";
import { getAllProducts } from "../../domain/usecase/GetAllProductsUseCase";
import { getPendingQuotes } from "../../domain/usecase/GetPendingQuotesUseCase";
import { getProcessedQuotes } from "../../domain/usecase/GetProcessedQuotesUseCase";
import { editProduct } from "../../domain/usecase/EditProductUseCase";
import { NewProcessedQuote } from "../../domain/models/NewProcessedQuote";
import { processPendingQuote } from "../../domain/usecase/ProcessPendingQuoteUseCase";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";
import { deletePendingQuote } from "../../domain/usecase/DeletePendingQuoteUseCase";
import { uploadPDFQuote } from "../../domain/usecase/UploadPDFQuoteUseCase";
import { getSets } from "../../domain/usecase/GetSetsUseCase";
import { NewSet } from "../../domain/models/NewSet";
import { addSet } from "../../domain/usecase/AddSetUseCase";
import { deleteSet } from "../../domain/usecase/DeleteSetUseCase";
import { updateSet } from "../../domain/usecase/UpdateSetUseCase";
import { updateProduct } from "../../domain/usecase/UpdateProductUseCase";
import { updateSetName } from "../../domain/usecase/UpdateSetNameUseCase";

export function useAdminModelController(
  productsRepository: ProductsRepository,
  quotesRepository: QuotesRepository
) {
  const handleGetAllSets = async (): Promise<Set[] | null> => {
    return await getSets(productsRepository);
  };
  const handleGetAllProducts = async (): Promise<ProductData[]> => {
    return await getAllProducts(productsRepository);
  };

  const handleGetAllPendingQuotes = async (): Promise<PendingQuote[]> => {
    return await getPendingQuotes(quotesRepository);
  };

  const handleGetAllProcessedQuotes = async (): Promise<ProcessedQuote[]> => {
    return await getProcessedQuotes(quotesRepository);
  };

  const handleAddProduct = async (
    product: NewProductData,
    categoryLevel: boolean
  ): Promise<boolean> => {
    return await addProduct(product, categoryLevel, productsRepository);
  };

  const handleDeleteProduct = async (
    product: ProductData
  ): Promise<boolean> => {
    return await deleteProduct(product, productsRepository);
  };

  const handleEditProduct = async (
    documentId: string,
    product: NewProductData,
    currentUrls: string[],
    coverImage: File | undefined,
    productImages: File[],
    imagesToDelete: string[]
  ): Promise<boolean> => {
    return await editProduct(
      documentId,
      product,
      currentUrls,
      coverImage,
      productImages,
      imagesToDelete,
      productsRepository
    );
  };

  const handleProcessPendingQuote = async (
    pendingQuoteId: string,
    newProcessedQuote: NewProcessedQuote,
    firebaseEmail: FirebaseEmail
  ): Promise<boolean> => {
    return await processPendingQuote(
      pendingQuoteId,
      newProcessedQuote,
      quotesRepository,
      firebaseEmail
    );
  };

  const handleUploadPDFQuote = async (
    clientEmail: string,
    pdf: File,
    fileName: string
  ): Promise<string> => {
    return await uploadPDFQuote(clientEmail, pdf, fileName, quotesRepository);
  };

  const handleDeletePendingQuote = async (
    pendingQuoteId: string
  ): Promise<boolean> => {
    return await deletePendingQuote(pendingQuoteId, quotesRepository);
  };

  const handleAddSet = async (set: NewSet): Promise<boolean> => {
    return await addSet(set, productsRepository);
  };

  const handleDeleteSet = async (set: Set): Promise<boolean> => {
    return await deleteSet(set, productsRepository);
  };

  const handleUpdateSet = async (documentId: string, set: NewSet) => {
    return await updateSet(documentId, set, productsRepository);
  };

  const handleUpdateSetName = async (documentId: string, setName: string) => {
    return await updateSetName(documentId, setName, productsRepository);
  };

  const handleUpdateProduct = async (
    documentId: string,
    product: ProductData
  ) => {
    return await updateProduct(documentId, product, productsRepository);
  };

  return {
    handleGetAllSets,
    handleGetAllProducts,
    handleGetAllPendingQuotes,
    handleGetAllProcessedQuotes,
    handleAddProduct,
    handleDeleteProduct,
    handleEditProduct,
    handleProcessPendingQuote,
    handleUploadPDFQuote,
    handleDeletePendingQuote,
    handleAddSet,
    handleDeleteSet,
    handleUpdateSet,
    handleUpdateSetName,
    handleUpdateProduct,
  };
}
