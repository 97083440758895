import { NewSet } from "../models/NewSet";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function updateSet(
  documentId: string,
  set: NewSet,
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.updateSet(documentId, set);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
