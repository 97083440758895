import { ProductData } from "../../../domain/models";
import { Price } from "./Price";

export function ProductInfoComponent({ product }: { product: ProductData }) {
  return (
    <div className="font-medium">
      <h1 className="leading-relaxed font-extrabold text-3xl text-palette-primary py-2 sm:py-4">
        {product.title}
      </h1>
      <p className="font-medium text-lg">{product.description}</p>
      <div className="text-xl text-palette-primary font-medium py-4 px-1">
        <Price product={product} size="text-2xl" />
      </div>
    </div>
  );
}
