export const formatItalianDate = (text: string): string => {
  const date = new Date(text);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const formattedDate = `${addZero(day)}/${addZero(month)}/${year}`;

  return formattedDate;
};

const addZero = (value: number): string => {
  return value < 10 ? `0${value}` : `${value}`;
};

export const getFormattedDate = (quoteDate: number): string => {
  const date = new Date(quoteDate);

  // Funzione per aggiungere uno zero iniziale se il numero è minore di 10
  const padZero = (num: number): string => num.toString().padStart(2, "0");

  // Formatta la data come desiderato (es. "DD/MM/YYYY HH:MM:SS")
  const formattedDate = `${padZero(date.getDate())}/${padZero(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;

  return formattedDate;
};
export const getFormattedDateWithHours = (quoteDate: number): string => {
  const date = new Date(quoteDate);

  // Funzione per aggiungere uno zero iniziale se il numero è minore di 10
  const padZero = (num: number): string => num.toString().padStart(2, "0");

  // Formatta la data come desiderato (es. "DD/MM/YYYY HH:MM:SS")
  const formattedDate = `${padZero(date.getDate())}/${padZero(
    date.getMonth() + 1
  )}/${date.getFullYear()} ${padZero(date.getHours())}:${padZero(
    date.getMinutes()
  )}:${padZero(date.getSeconds())}`;

  return formattedDate;
};
