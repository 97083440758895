import anselmo_logo from "../../../assets/images/anselmo_logo.webp";
import audi_logo from "../../../assets/images/audi_logo.webp";
import balocco_logo from "../../../assets/images/balocco_logo.webp";
import btm_logo from "../../../assets/images/btm_logo.webp";
import carglass_logo from "../../../assets/images/carglass_logo.webp";
import colussi_logo from "../../../assets/images/colussi_logo.webp";
import confindustria_logo from "../../../assets/images/confindustria_logo.webp";
import daikin_logo from "../../../assets/images/daikin_logo.webp";
import decathlon_logo from "../../../assets/images/decathlon_logo.webp";
import hermes_logo from "../../../assets/images/hermes_logo.webp";
import il_molino_logo from "../../../assets/images/il_molino_logo.webp";
import maina_logo from "../../../assets/images/maina_logo.webp";
import mediolanum_logo from "../../../assets/images/mediolanum_logo.webp";
import new_holland_logo from "../../../assets/images/new_holland_logo.webp";
import opel_logo from "../../../assets/images/opel_logo.webp";
import rica_lewis_logo from "../../../assets/images/rica_lewis_logo.webp";
import sella_logo from "../../../assets/images/sella_logo.webp";
import zara_logo from "../../../assets/images/zara_logo.webp";

const customers = [
  { id: 1, src: anselmo_logo },
  { id: 2, src: audi_logo },
  { id: 3, src: balocco_logo },
  { id: 4, src: btm_logo },
  { id: 5, src: carglass_logo },
  { id: 6, src: colussi_logo },
  { id: 7, src: confindustria_logo },
  { id: 8, src: daikin_logo },
  { id: 9, src: decathlon_logo },
  { id: 10, src: hermes_logo },
  { id: 11, src: il_molino_logo },
  { id: 12, src: maina_logo },
  { id: 13, src: mediolanum_logo },
  { id: 14, src: new_holland_logo },
  { id: 15, src: opel_logo },
  { id: 16, src: rica_lewis_logo },
  { id: 17, src: sella_logo },
  { id: 18, src: zara_logo },
];

export function CustomersComponent() {
  return (
    <div className="bg-white pb-24 sm:pb-36">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-4xl font-semibold leading-8 text-gray-900">
          Chi abbiamo servito
        </h2>
        <div className="mx-auto mt-20 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {customers.map((customer) => (
            <img
              key={customer.id}
              className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
              src={customer.src}
              alt="Transistor"
              width={158}
              height={48}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
