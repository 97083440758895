import { ProductData } from "../../../domain/models";

function ProductCatalogueItem({
  product,
  navigateToProduct,
}: {
  product: ProductData;
  navigateToProduct: (productId: string) => void;
}) {
  const discountedPrice =
    product.price - product.price * (product.discount / 100);

  return (
    <div
      key={product.id}
      className="group relative"
      onClick={() => navigateToProduct(product.id)}
    >
      <div className="relative aspect-h-1 aspect-w-1 w-full">
        <img
          src={product.images[0]}
          alt="Example"
          className="w-full h-full object-contain rounded-lg"
        />
        {product.discount > 0 ? (
          <div className="absolute mt-auto text-sm mb-4 font-semibold py-1 px-4 bg-red-600 text-white h-fit w-fit rounded-r-md">
            Promo
          </div>
        ) : null}
      </div>

      <div className="mt-4 flex flex-col justify-between">
        <h3 className="text-sm font-medium text-gray-700">
          <span aria-hidden="true" className="absolute inset-0" />
          {product.title}
        </h3>
        <p className="mt-1 text-sm text-gray-500 line-clamp-2">
          {product.description}
        </p>

        {product.discount > 0 ? (
          <div className="mt-2">
            <p className="text-base font-medium text-gray-900">
              {discountedPrice.toFixed(2).replace(".", ",")}€
            </p>
            <p className="text-sm">
              Prezzo normale:{" "}
              <span className="line-through">
                {product.price.toFixed(2).replace(".", ",")} €
              </span>{" "}
              <span className="text-red-600 ">
                Sconto {product.discount.toFixed(0).replace(".", ",")}%
              </span>
            </p>
          </div>
        ) : (
          <p className="mt-2 text-base font-medium text-gray-900">
            {product.price}€
          </p>
        )}
      </div>
    </div>
  );
}

export default ProductCatalogueItem;
