import { ProductData } from "../models";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function updateProduct(
  documentId: string,
  product: ProductData,
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.updateProduct(documentId, product);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
