import { QuoteRequest } from "../models";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function createPendingQuote(
  quoteRequest: QuoteRequest,
  repository: QuotesRepository
): Promise<boolean> {
  const result = await repository.createQuoteRequest(quoteRequest);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
