import { FirebaseEmail } from "../models/FirebaseEmail";
import { NewProcessedQuote } from "../models/NewProcessedQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function deletePendingQuote(
  pendingQuoteId: string,
  repository: QuotesRepository
): Promise<boolean> {
  const result = await repository.deletePendingQuote(pendingQuoteId);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
