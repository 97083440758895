import { NewSet } from "../models/NewSet";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function updateSetName(
  documentId: string,
  setName: string,
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.updateSetName(documentId, setName);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
