import { useLocation, useNavigate, useParams } from "react-router-dom";
import { HeaderComponent } from "../components/client/HeaderComponent";
import { useEffect, useState } from "react";
import { useClientModelController } from "../hook/useClientModelController";
import { ProductsRepository } from "../../domain/repository";
import { ProductData } from "../../domain/models";
import {
  findSubcategories,
  getCategoryFromFullPath,
} from "../../utils/productUtils";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import ProductCatalogueItem from "../components/admin/ProductCatalogueItem";
import AnimationComponent from "../components/AnimationComponent";
import noProducts from "../../assets/lotties/no_products.json";
import loadingAnimation from "../../assets/lotties/loading.json";
import SetCatalogueItem from "../components/admin/SetCatalogueItem";
import { Set } from "../../domain/models/Set";
import SubCategoryItem from "../components/admin/SubCategoryItem";

export function CatalogPage({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { category, subcategory } = useParams();
  const { handleGetAllSets, handleGetAllProducts } = useClientModelController(
    productsRepository,
    quotesRepository
  );
  const [productList, setProductList] = useState<ProductData[] | null>(null);
  const [sets, setSets] = useState<Set[] | null>(null);
  const [subcategories, setSubcategories] = useState<string[] | null>(null);

  useEffect(() => {
    if (category && !subcategory) {
      setSubcategories(findSubcategories(category));
      loadProducts(category);
    }
  }, [category, location]);

  useEffect(() => {
    if (subcategory) {
      setSubcategories(null);
      setProductList(null);
      setSets(null);
      loadProducts(subcategory);
    }
  }, [subcategory, location]);

  const loadProducts = async (category: string) => {
    const setsResult = await handleGetAllSets();
    var filteredSets =
      setsResult?.filter((e) =>
        subcategory
          ? e.subcategory?.toLowerCase() === subcategory.toLowerCase()
          : e.category.toLowerCase() === category.toLowerCase()
      ) ?? [];
    setSets(filteredSets);
    const products: ProductData[] = await handleGetAllProducts();
    const filteredProducts = products.filter((product) => {
      const productCategoryLastPart = getCategoryFromFullPath(product.category);

      return productCategoryLastPart === category.toLowerCase();
    });

    setProductList(filteredProducts);
  };

  const navigateToProduct = (productId: string) => {
    navigate(`/catalog/${category}/${subcategory ?? "product"}/${productId}`);
  };

  const navigateToSubCategory = (subcategory: string) => {
    navigate(`/catalog/${category}/${subcategory}`);
  };

  if (productList === null) {
    return (
      <div className="my-auto">
        <AnimationComponent
          animation={loadingAnimation}
          message="Caricamento prodotti in corso"
        />
      </div>
    );
  }

  return (
    <div className="w-full min-h-full">
      <HeaderComponent
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
      <nav className="mx-auto w-full pt-6">
        <ol
          role="list"
          className="mx-auto flex px-8 xl:px-0 items-center space-x-2 lg:max-w-7xl"
        >
          <li key={"home"}>
            <div className="flex items-center">
              <a
                href="/"
                className="mr-2 text-sm font-medium text-gray-900 capitalize"
              >
                Home
              </a>
              <svg
                fill="currentColor"
                width={16}
                height={20}
                viewBox="0 0 16 20"
                aria-hidden="true"
                className="h-5 w-4 text-gray-300"
              >
                <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
              </svg>
            </div>
          </li>
          <li key={category}>
            <div className="flex items-center">
              <a
                href={`/catalog/${category}`}
                className="mr-2 text-sm font-medium text-gray-900 capitalize"
              >
                {category}
              </a>
              {subcategory && (
                <svg
                  fill="currentColor"
                  width={16}
                  height={20}
                  viewBox="0 0 16 20"
                  aria-hidden="true"
                  className="h-5 w-4 text-gray-300"
                >
                  <path d="M5.697 4.34L8.98 16.532h1.327L7.025 4.341H5.697z" />
                </svg>
              )}
            </div>
          </li>

          {subcategory && (
            <li className="text-sm">
              <a
                href={`/catalog/${category}/${subcategory}`}
                aria-current="page"
                className="text-sm font-medium text-gray-900 capitalize"
              >
                {subcategory}
              </a>
            </li>
          )}
        </ol>
      </nav>

      <div className="mx-auto px-8 xl:px-0 lg:max-w-7xl">
        <div className="flex flex-col space-y-12">
          {subcategories !== null && subcategories.length > 0 && (
            <>
              {
                <div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-10">
                    {subcategories?.map((subcategory) => (
                      <SubCategoryItem
                        categoryName={subcategory}
                        onClick={(subcategory) =>
                          navigateToSubCategory(subcategory)
                        }
                      />
                    ))}
                  </div>
                </div>
              }
            </>
          )}

          {sets !== null &&
            sets?.length > 0 &&
            (!subcategories || subcategories.length === 0) && (
              <>
                <p className="font-bold text-2xl mt-6 mb-4">Set</p>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-10">
                  {sets.map((set) => (
                    <SetCatalogueItem
                      key={set.name}
                      productName={set.name}
                      productImage={set.image}
                      navigateToProduct={(productId) =>
                        navigateToProduct(productId)
                      }
                    />
                  ))}
                </div>
              </>
            )}

          {productList !== null &&
            productList.length > 0 &&
            (subcategories === null || subcategories.length === 0) && (
              <>
                {sets !== null && sets?.length > 0 && (
                  <p className="font-bold text-2xl mb-4">Prodotti</p>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-10">
                  {productList.map((product) => (
                    <ProductCatalogueItem
                      key={product.id}
                      product={product}
                      navigateToProduct={(productId) =>
                        navigateToProduct(productId)
                      }
                    />
                  ))}
                </div>
              </>
            )}
          {(productList === null || productList.length === 0) &&
            (subcategories === null || subcategories?.length === 0) &&
            sets?.length === 0 && (
              <div className="col-span-full">
                <AnimationComponent
                  animation={noProducts}
                  message="Non ci sono prodotti disponibili."
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
