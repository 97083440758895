import { useEffect, useState } from "react";
import {
  useCartContext,
  useUpdateCartQuantityContext,
} from "../../context/cart";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { HeaderComponent } from "../components/client/HeaderComponent";
import { ProductCart } from "../../domain/models/ProductCart";
import { useClientModelController } from "../hook/useClientModelController";
import { mailIsValid, phoneIsValid } from "../../utils/fieldUtils";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import DatePickerCustom from "../components/DatePickerCustom";
import { useNavigate } from "react-router-dom";
import { Set } from "../../domain/models/Set";
import { getCategoryFromFullPath } from "../../utils/productUtils";

export function CheckoutPage({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const [cartProducts, setCartProducts] = useState<ProductCart[]>([]);
  const [fullName, setFullName] = useState<string>("");
  const [fullNameValid, setFullNameValid] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [addressValid, setAddressValid] = useState<boolean>(false);
  const [city, setCity] = useState<string>("");
  const [cityValid, setCityValid] = useState<boolean>(false);
  const [cap, setCap] = useState<string>("");
  const [capValid, setCapValid] = useState<boolean>(false);

  const [shippingAddress, setShippingAddress] = useState<string>("");
  const [shippingAddressValid, setShippingAddressValid] =
    useState<boolean>(false);
  const [shippingCity, setShippingCity] = useState<string>("");
  const [shippingCityValid, setShippingCityValid] = useState<boolean>(false);
  const [shippingCap, setShippingCap] = useState<string>("");
  const [shippingCapValid, setShippingCapValid] = useState<boolean>(false);

  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);
  const [eventDate, setEventDate] = useState<Date | null>(null);
  const [returnDate, setReturnDate] = useState<Date | null>(null);

  const [hasSubmit, setHasSubmit] = useState<boolean>(false);
  const [isCreatingQuoteRequest, setIsCreatingQuoteRequest] =
    useState<boolean>(false);

  const { handleCreatePendingQuote } = useClientModelController(
    productsRepository,
    quotesRepository
  );

  const cart = useCartContext();
  const updateCartQuantity = useUpdateCartQuantityContext();
  const [sets, setSets] = useState<Set[] | null>(null);
  const { handleGetAllSets } = useClientModelController(
    productsRepository,
    quotesRepository
  );
  useEffect(() => {
    if (!sets) {
      getSets();
    }
  }, []);

  const getSets = async () => {
    const setsResult = await handleGetAllSets();
    setSets(setsResult);
  };
  useEffect(() => {
    let numItems = 0;
    let cartItems: ProductCart[] = [];
    cart.forEach((item) => {
      cartItems.push(item);
      numItems += item?.quantity ?? 0;
    });
    setCartProducts(cartItems);
  }, [cart]);

  useEffect(() => {
    const isNameValid = fullName.trim().length > 3;
    const isEmailValid = mailIsValid(email);
    const isPhoneValid = phoneIsValid(phone);
    const isAddressValid = address.trim().length > 3;
    const isCityValid = city.trim().length > 1;
    const isCapValid = cap.trim().length === 5;

    const isShippingAddressValid = shippingAddress.trim().length > 3;
    const isShippingCityValid = shippingCity.trim().length > 1;
    const isShippingCapValid = shippingCap.trim().length === 5;

    setFullNameValid(isNameValid);
    setEmailValid(isEmailValid);
    setPhoneValid(isPhoneValid);
    setAddressValid(isAddressValid);
    setCityValid(isCityValid);
    setCapValid(isCapValid);
    setShippingAddressValid(isShippingAddressValid);
    setShippingCityValid(isShippingCityValid);
    setShippingCapValid(isShippingCapValid);
  }, [
    fullName,
    email,
    phone,
    address,
    city,
    cap,
    shippingAddress,
    shippingCity,
    shippingCap,
    deliveryDate,
    eventDate,
    returnDate,
  ]);

  const calculateTotal = (): string => {
    let total = 0;

    cartProducts.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });

    return total.toFixed(2).replace(".", ",");
  };

  const calculateTax = (): string => {
    let total = 0;

    cartProducts.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });

    return (total * 0.22).toFixed(2).replace(".", ",");
  };

  const calculateTotalWithTax = (): string => {
    let total = 0;

    cartProducts.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    let tax = total * 0.22;
    return (total + tax).toFixed(2).replace(".", ",");
  };

  const validateForm = (): boolean => {
    setHasSubmit(true);
    const isNameValid = fullName.length > 3;
    const isEmailValid = mailIsValid(email);
    const isPhoneValid = phoneIsValid(phone);
    const isAddressValid = address.length > 3;
    const isCityValid = city.length > 1;
    const isCapValid = cap.length === 5;

    const isShippingAddressValid = shippingAddress.length > 3;
    const isShippingCityValid = shippingCity.length > 1;
    const isShippingCapValid = shippingCap.length === 5;

    const isDeliveryDateValid = deliveryDate !== null;
    const isEventDateValid = eventDate !== null;
    const isReturnDateValid = returnDate !== null;

    return (
      isNameValid &&
      isEmailValid &&
      isPhoneValid &&
      isAddressValid &&
      isCityValid &&
      isCapValid &&
      isShippingAddressValid &&
      isShippingCityValid &&
      isShippingCapValid &&
      isDeliveryDateValid &&
      isEventDateValid &&
      isReturnDateValid
    );
  };

  const createPendingQuotes = async () => {
    setIsCreatingQuoteRequest(true);

    const result = await handleCreatePendingQuote({
      name: fullName.trim(),
      email,
      phone,
      address,
      city,
      cap,
      cart: cartProducts,
      date: Date.now(),
      shippingAddress,
      deliveryDate: deliveryDate!.getTime(),
      eventDate: eventDate!.getTime(),
      returnDate: returnDate!.getTime(),
      shippingCity,
      shippingCap,
    });
    if (result) {
      navigate("/thankYou");
      resetAllFields();
      for (var cartItem of cartProducts) {
        updateCartQuantity(cartItem.id, 0);
      }
      setIsCreatingQuoteRequest(false);
    } else {
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo d i riprovare più tardi."
      );
      setIsCreatingQuoteRequest(false);
    }
  };

  const resetAllFields = () => {
    setFullName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setCity("");
    setCap("");
    setShippingAddress("");
    setShippingCity("");
    setShippingCap("");
    setDeliveryDate(null);
    setEventDate(null);
    setReturnDate(null);
    setHasSubmit(false);
  };

  const handleEventDateSelect = (date: number, calendarIndex: number) => {
    const selectedDate = new Date(date);

    if (calendarIndex === 1) {
      setDeliveryDate(selectedDate);
      setEventDate(null); // Reset subsequent dates
      setReturnDate(null);
    } else if (calendarIndex === 2) {
      setEventDate(selectedDate);
      setReturnDate(null); // Reset subsequent dates
    } else if (calendarIndex === 3) {
      setReturnDate(selectedDate);
    }
  };

  const getSetFromProduct = (product: ProductCart): Set | null => {
    const set = sets?.filter(
      (set) =>
        set.name.toLowerCase() === getCategoryFromFullPath(product.category)
    );
    return set ? set[0] : null;
  };

  const groupProductsBySet = (products: ProductCart[]) => {
    const grouped: { [key: string]: ProductCart[] } = {};

    products.forEach((product) => {
      const set = getSetFromProduct(product);
      if (set) {
        if (!grouped[set.name]) {
          grouped[set.name] = [];
        }
        grouped[set.name].push(product);
      } else {
        if (!grouped[product.id]) {
          grouped[product.id] = [];
        }
        grouped[product.id].push(product);
      }
    });

    return grouped;
  };

  const groupedProducts = groupProductsBySet(cartProducts);

  return (
    <div className="w-full min-h-full">
      <HeaderComponent
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
      <div className="grid px-4 sm:px-16 xl:grid-cols-2 gap-10">
        <div className="pt-8">
          <p className="text-xl font-medium">Riepilogo ordine</p>
          <p className="text-gray-400">
            Ecco l'elenco dei prodotti che hai richiesto.
          </p>
          <div className="mt-8 space-y-2 rounded-lg border bg-white px-2 sm:px-6">
            {groupedProducts ? (
              Object.entries(groupedProducts).map(
                ([groupKey, groupProducts]) => {
                  const set = sets?.find((set) => set.name === groupKey);
                  return (
                    <li key={groupKey} className="flex flex-col py-6">
                      <div className="flex items-start">
                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                          <img
                            src={set ? set.image : groupProducts[0].image}
                            alt={set ? set.image : groupProducts[0].image}
                            className="h-full w-full object-contain object-center"
                          />
                        </div>
                        <div className="ml-4 flex flex-1 flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>
                                <a
                                  href={
                                    set
                                      ? set.subcategory
                                        ? `/catalog/${set.category}/${set.subcategory}/${set.name}`
                                        : `/catalog/${set.category}/${set.name}`
                                      : `/catalog/${groupProducts[0].id}`
                                  }
                                >
                                  {set ? set.name : groupProducts[0].title}
                                </a>
                              </h3>
                              <p className="ml-4">
                                {groupProducts
                                  .reduce(
                                    (total, product) =>
                                      total + product.price * product.quantity,
                                    0
                                  )
                                  .toFixed(2)
                                  .replace(".", ",")}
                                €
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="mt-2 space-y-2">
                        {groupProducts.map((product) => (
                          <li
                            key={product.id}
                            className="flex items-center  text-sm"
                          >
                            <div className="flex w-full justify-end">
                              <p className="text-gray-500 font-medium">
                                {product.title}
                              </p>
                              <p className="ml-4 text-gray-500">
                                Qtà {product.quantity}
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                }
              )
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
        </div>
        <div className="mt-4 pt-8 xl:mt-0 bg-gray-50 px-2 sm:px-10 xl:rounded-b-lg xl:rounded-t-none rounded-lg mb-6">
          <p className="text-xl font-medium">Informazioni di contatto</p>
          <p className="text-gray-400">
            Completa l'ordine inserendo le tue informazioni personali per poter
            ricevere il preventivo.
          </p>

          <div className="">
            <label
              htmlFor="full-name"
              className="mt-4 mb-2 block text-sm font-medium"
            >
              Nome e cognome
            </label>
            <div className="relative">
              <input
                type="text"
                id="full-name"
                name="fullname"
                className={`w-full rounded-md border ${
                  !fullNameValid && hasSubmit
                    ? "border-red-500"
                    : "border-gray-200"
                } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="Mario Rossi"
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
            </div>
            {!fullNameValid && hasSubmit && (
              <span className="text-red-500 text-xs">Compila questo campo</span>
            )}
            <label
              htmlFor="email"
              className="mt-4 mb-2 block text-sm font-medium"
            >
              Email
            </label>
            <div className="relative">
              <input
                type="text"
                id="email"
                name="email"
                className={`w-full rounded-md border ${
                  !emailValid && hasSubmit
                    ? "border-red-500"
                    : "border-gray-200"
                } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="your.email@gmail.com"
                onChange={(e) => setEmail(e.target.value.trim())}
                value={email}
              />
            </div>
            {!emailValid && hasSubmit && (
              <span className="text-red-500 text-xs">
                Inserisci un valore valido
              </span>
            )}
            <label
              htmlFor="phone"
              className="mt-4 mb-2 block text-sm font-medium"
            >
              Telefono
            </label>
            <div className="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                className={`w-full rounded-md border ${
                  !phoneValid && hasSubmit
                    ? "border-red-500"
                    : "border-gray-200"
                } px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                placeholder="333 3333 333"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
            </div>
            {!phoneValid && hasSubmit && (
              <span className="text-red-500 text-xs">
                Inserisci un valore valido
              </span>
            )}
            <label
              htmlFor="address"
              className="mt-4 mb-2 block text-sm font-medium"
            >
              Indirizzo
            </label>
            <div className="grid grid-cols-5 gap-x-2">
              <div className="col-span-3">
                <input
                  type="text"
                  id="address"
                  name="address"
                  className={`w-full rounded-md border ${
                    !addressValid && hasSubmit
                      ? "border-red-500"
                      : "border-gray-200"
                  } px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                  placeholder="Via/Corso"
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                />

                {!addressValid && hasSubmit && (
                  <span className="text-red-500 text-xs">
                    Inserisci un valore valido
                  </span>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="city"
                  className={`w-full rounded-md border ${
                    !cityValid && hasSubmit
                      ? "border-red-500"
                      : "border-gray-200"
                  } px-2 py-3  text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                  placeholder="Città"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
                {!cityValid && hasSubmit && (
                  <span className="text-red-500 text-xs">
                    Inserisci un valore valido
                  </span>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="zip"
                  className={`w-full rounded-md border ${
                    !capValid && hasSubmit
                      ? "border-red-500"
                      : "border-gray-200"
                  } px-2 py-3  text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                  placeholder="CAP"
                  onChange={(e) => setCap(e.target.value.trim())}
                  value={cap}
                />
                {!capValid && hasSubmit && (
                  <span className="text-red-500 text-xs">
                    Inserisci un valore valido
                  </span>
                )}
              </div>
            </div>

            <label
              htmlFor="shipping-address"
              className="mt-4 mb-2 block text-sm font-medium"
            >
              Indirizzo di spedizione
            </label>
            <div className="grid grid-cols-5 gap-x-2">
              <div className="col-span-3">
                <input
                  type="text"
                  id="shipping-address"
                  name="shipping-address"
                  className={`w-full rounded-md border ${
                    !shippingAddressValid && hasSubmit
                      ? "border-red-500"
                      : "border-gray-200"
                  } px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                  placeholder="Via/Corso"
                  onChange={(e) => setShippingAddress(e.target.value)}
                  value={shippingAddress}
                />

                {!shippingAddress && hasSubmit && (
                  <span className="text-red-500 text-xs">
                    Inserisci un valore valido
                  </span>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="shipping-city"
                  className={`w-full rounded-md border ${
                    !shippingCityValid && hasSubmit
                      ? "border-red-500"
                      : "border-gray-200"
                  } px-2 py-3  text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                  placeholder="Città"
                  onChange={(e) => setShippingCity(e.target.value)}
                  value={shippingCity}
                />
                {!shippingCityValid && hasSubmit && (
                  <span className="text-red-500 text-xs">
                    Inserisci un valore valido
                  </span>
                )}
              </div>
              <div>
                <input
                  type="text"
                  name="shipping-zip"
                  className={`w-full rounded-md border ${
                    !shippingCapValid && hasSubmit
                      ? "border-red-500"
                      : "border-gray-200"
                  } px-2 py-3  text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500`}
                  placeholder="CAP"
                  onChange={(e) => setShippingCap(e.target.value.trim())}
                  value={shippingCap}
                />
                {!shippingCapValid && hasSubmit && (
                  <span className="text-red-500 text-xs">
                    Inserisci un valore valido
                  </span>
                )}
              </div>
            </div>

            <div className="grid sm:grid-cols-6 grid-cols-1 gap-x-2">
              <div className="col-span-2 relative overflow-visible">
                <label
                  htmlFor="delivery-date"
                  className="mt-4 mb-2 block text-sm font-medium"
                >
                  Data consegna
                </label>
                <div className="w-full overflow-visible">
                  <DatePickerCustom
                    onDateSelect={(date) =>
                      handleEventDateSelect(date.getTime(), 1)
                    }
                    minDate={null}
                  />
                </div>
              </div>
              <div className="col-span-2 relative overflow-visible">
                <label
                  htmlFor="event-date"
                  className="mt-4 mb-2 block text-sm font-medium"
                >
                  Data evento
                </label>
                <div className="w-full overflow-visible">
                  <DatePickerCustom
                    onDateSelect={(date) =>
                      handleEventDateSelect(date.getTime(), 2)
                    }
                    minDate={deliveryDate}
                  />
                </div>
              </div>
              <div className="col-span-2 relative overflow-visible">
                <label
                  htmlFor="return-date"
                  className="mt-4 mb-2 block text-sm font-medium"
                >
                  Data riconsegna
                </label>
                <div className="w-full overflow-visible">
                  <DatePickerCustom
                    onDateSelect={(date) =>
                      handleEventDateSelect(date.getTime(), 3)
                    }
                    minDate={eventDate}
                  />
                </div>
              </div>
            </div>

            {/* <div className="mt-6 border-t border-b py-2">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Totale preventivo (approssimativo)
                </p>
                <p className="font-semibold text-gray-900">$399.00</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Spese di spedizione
                </p>
                <p className="font-semibold text-end text-gray-900">
                  Calcolate in fase di preventivo
                </p>
              </div>
            </div> */}
            <div className="mt-6">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Totale (escluse spese di spedizione)
                </p>

                <p className="text-sm font-semibold text-gray-900">
                  {calculateTotal()}€
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Iva 22%</p>
                <p className="text-sm font-semibold text-gray-900">
                  {calculateTax()}€
                </p>
              </div>
              <div className="mt-1 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">
                  Totale inclusa iva
                </p>
                <p className="text-2xl font-semibold text-gray-900">
                  {calculateTotalWithTax()}€
                </p>
              </div>
            </div>
          </div>
          <button
            disabled={isCreatingQuoteRequest}
            onClick={() => {
              if (validateForm()) {
                createPendingQuotes();
              }
            }}
            className="mt-4 mb-8 w-full rounded-md bg-palette-primary hover:bg-palette-dark px-6 py-3 font-medium text-white"
          >
            {isCreatingQuoteRequest
              ? "Invio richiesta..."
              : "Richiedi preventivo"}
          </button>
        </div>
      </div>
    </div>
  );
}
