import { FirebaseEmail } from "../models/FirebaseEmail";
import { NewProcessedQuote } from "../models/NewProcessedQuote";
import { Success } from "../models/Result";
import { QuotesRepository } from "../repository/QuotesRepository";

export async function processPendingQuote(
  pendingQuoteId: string,
  newProcessedQuote: NewProcessedQuote,
  repository: QuotesRepository,
  firebaseEmail: FirebaseEmail
): Promise<boolean> {
  const result = await repository.processPendingQuote(
    pendingQuoteId,
    newProcessedQuote,
    firebaseEmail
  );
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
