import { useLocation } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { ProcessedQuote } from "../../domain/models/ProcessedQuote";
import { formatItalianDate, getFormattedDate } from "../../utils/dateUtils";
import { ProductCart } from "../../domain/models/ProductCart";
import { AuthRepository } from "../../domain/repository/AuthRepository";

export default function SendedQuoteDetailView({
  authRepository,
}: {
  authRepository: AuthRepository;
}) {
  const location = useLocation();
  const quote: ProcessedQuote = location.state.quote;

  return (
    <div className="w-full min-h-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="justify-between border-b border-gray-200 my-4 md:my-10">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Dettaglio preventivo
          </h1>
          <p className="mt-5 text-sm leading-5 text-gray-500">
            <time dateTime={quote.date}>{formatItalianDate(quote.date)}</time>
          </p>
          <h3 className="mt-2">
            <span className="font-bold">Cliente:</span> {quote.toName}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Email:</span> {quote.toEmail}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Indirizzo di spedizione:</span>{" "}
            {quote.shippingAddress} {quote.shippingCity} {quote.shippingCap}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data consegna:</span>{" "}
            {getFormattedDate(quote.deliveryDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data evento:</span>{" "}
            {getFormattedDate(quote.eventDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data ritiro:</span>{" "}
            {getFormattedDate(quote.returnDate)}
          </h3>
          <h2 className="font-bold mt-10">Testo del preventivo</h2>
          <p className="mt-4">{quote.text}</p>
          <h2 className="font-bold mt-10">Preventivo</h2>
          <a
            className="mt-4 text-blue-500"
            href={quote.pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Visualizza PDF
          </a>
          <label
            htmlFor="description"
            className="font-bold block text-sm leading-6 text-gray-900 mt-10"
          >
            Carrello
          </label>
          <div
            className="mt-6 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {quote.cart.length > 0 ? (
              quote.cart.map((product: ProductCart) => (
                <div
                  className="flex flex-col rounded-lg bg-white sm:flex-row"
                  key={product.id}
                >
                  <img
                    className="m-2 h-24 w-28 rounded-md border object-contain object-center"
                    src={product.image}
                    alt="product"
                  />
                  <div className="flex w-full flex-col px-4 py-4">
                    <span className="font-semibold">{product.title}</span>
                    <span className="float-right text-gray-400">
                      Quantità: {product.quantity}
                    </span>
                    <div className="flex space-x-3">
                      <p className="text-lg font-bold">{product.price}€</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
          <h3 className="mt-6 mb-2">
            <span className="font-bold text-lg">Costi di spedizione:</span>{" "}
            {quote.shippingPrice.toFixed(2).replace(".", ",")}€
          </h3>
          <span className="font-bold text-lg">Totale preventivo:</span>{" "}
          {quote.totalPrice.toFixed(2).replace(".", ",") ?? 0}€
          <div className="mt-10"></div>
        </div>
      </main>
      <FooterComponent />
    </div>
  );
}
