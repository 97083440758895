import "./App.css";
import "../firebase.config";
import { Routes, Route } from "react-router-dom";
import {
  CheckoutPage,
  HomeView,
  NotFoundView,
  PrivateRoute,
  ProductPage,
} from "../presentation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CatalogPage } from "../presentation/client/CatalogPage";
import { CartProvider } from "../context/cart";
import { DashboardView } from "../presentation/admin/DashboardView";
import { ProductDetailView } from "../presentation/admin/ProductDetailView";
import { PendingQuotesView } from "../presentation/admin/PendingQuotesView";
import PendingQuoteDetailView from "../presentation/admin/PendingQuoteDetailView";
import { SendedQuotesView } from "../presentation/admin/SendedQuotesView";
import SendedQuoteDetailView from "../presentation/admin/SendedQuoteDetailView";
import {
  ProductsDataSourceImpl,
  QuotesDatasourceImpl,
} from "../data/datasource";
import { ProductsRepositoryImpl } from "../data/repository/ProductsRepositoryImpl";
import { QuotesRepositoryImpl } from "../data/repository/QuotesRepositoryImpl";
import { SignInView } from "../presentation/admin/SignInView";
import { AuthDataSourceImpl } from "../data/datasource/AuthDataSourceImpl";
import { AuthRepositoryImpl } from "../data/repository/AuthRepositoryImpl";
import ThankYouPage from "../presentation/client/ThankYouPage";
import PrivacyPolicyPage from "../presentation/client/PrivacyPolicyPage";
import TermsConditionsPage from "../presentation/client/TermsConditionsPage";

const productsDataSource = new ProductsDataSourceImpl();
const productsRepository = new ProductsRepositoryImpl(productsDataSource);
const quotesDataSource = new QuotesDatasourceImpl();
const quotesRepository = new QuotesRepositoryImpl(quotesDataSource);
const authDataSource = new AuthDataSourceImpl();
const authRepository = new AuthRepositoryImpl(authDataSource);

function App() {
  return (
    <CartProvider>
      <Routes>
        <Route path="*" element={<NotFoundView />} />
        <Route
          path="/"
          element={
            <HomeView
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-conditions" element={<TermsConditionsPage />} />
        <Route
          path="/catalog/:category/:subcategory/:productId"
          element={
            <ProductPage
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />
        <Route
          path="/catalog/:category/:subcategory"
          element={
            <CatalogPage
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />
        <Route
          path="/catalog/:category/:productId"
          element={
            <ProductPage
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />
        <Route
          path="/catalog/:category"
          element={
            <CatalogPage
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />
        <Route
          path="/checkout"
          element={
            <CheckoutPage
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />

        <Route
          path="/thankYou"
          element={
            <ThankYouPage
              productsRepository={productsRepository}
              quotesRepository={quotesRepository}
            />
          }
        />

        <Route
          path="/signIn"
          element={<SignInView authRepository={authRepository} />}
        />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route
            path="/dashboard"
            element={
              <DashboardView
                authRepository={authRepository}
                productRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            }
          />
          <Route
            path="/dashboard/pendingQuotes"
            element={
              <PendingQuotesView
                authRepository={authRepository}
                productRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            }
          />
          <Route
            path="/dashboard/sendedQuotes"
            element={
              <SendedQuotesView
                authRepository={authRepository}
                productRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            }
          />
          <Route
            path="/dashboard/:category/:productId"
            element={
              <ProductDetailView
                authRepository={authRepository}
                productRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            }
          />
          <Route
            path="/dashboard/:category/:subCategory/:productId"
            element={
              <ProductDetailView
                authRepository={authRepository}
                productRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            }
          />
          <Route
            path="/dashboard/pendingQuotes/:quoteId"
            element={
              <PendingQuoteDetailView
                authRepository={authRepository}
                productRepository={productsRepository}
                quotesRepository={quotesRepository}
              />
            }
          />
          <Route
            path="/dashboard/sendedQuotes/:quoteId"
            element={<SendedQuoteDetailView authRepository={authRepository} />}
          />
        </Route>
      </Routes>
      <ToastContainer autoClose={3000} />
    </CartProvider>
  );
}

export default App;
