import { useEffect } from "react";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { CategoriesComponent } from "../components/client/CategoriesComponent";
import { CustomersComponent } from "../components/client/CustomersComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { HeaderComponent } from "../components/client/HeaderComponent";
import { HeroComponent } from "../components/client/HeroComponent";
import { StatsComponent } from "../components/client/StatsComponent";
import { useClientModelController } from "../hook/useClientModelController";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";

export function HomeView({
  productsRepository,
  quotesRepository,
}: {
  productsRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const { handleGetAllSets, handleGetAllProducts } = useClientModelController(
    productsRepository,
    quotesRepository
  );
  useEffect(() => {
    handleGetAllSets();
    handleGetAllProducts();
  }, []);
  return (
    <div className="w-full min-h-full">
      <HeaderComponent
        productsRepository={productsRepository}
        quotesRepository={quotesRepository}
      />
      <HeroComponent />
      <CategoriesComponent />
      <CustomersComponent />
      <StatsComponent />
      <FooterComponent />
    </div>
  );
}
