import { NewProductData } from "../models";
import { Success } from "../models/Result";
import { ProductsRepository } from "../repository";

export async function addProduct(
  product: NewProductData,
  categoryLevel: boolean,
  repository: ProductsRepository
): Promise<boolean> {
  const result = await repository.addProduct(product, categoryLevel);
  if (result instanceof Success) {
    return result.value;
  } else {
    return false;
  }
}
