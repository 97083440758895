import {
  faFloppyDisk,
  faPenToSquare,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackToProductButton } from "../client/BackToProductButton";
import { ProductInfoComponent } from "../client/ProductInfoComponent";
import { useAdminModelController } from "../../hook/useAdminModelController";
import { ProductsRepository } from "../../../domain/repository";
import { QuotesRepository } from "../../../domain/repository/QuotesRepository";
import { ProductData } from "../../../domain/models/ProductData";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import ConfirmDialogComponent, { DialogType } from "./ConfirmDialogComponent";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { EditProductInfoComponent } from "./EditProductInfoComponent";
import { EditProductImageComponent } from "./EditProductImageComponent";
import { ProductImageComponent } from "../client/ProductImageComponent";
import { NewProductData } from "../../../domain/models/NewProductData";
import { LoaderView } from "../LoaderView";
import { Set } from "../../../domain/models";

export function ProductDetailComponent({
  productRepository,
  quotesRepository,
  product,
  isSet,
  allSets,
  productEdited,
}: {
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
  product: ProductData;
  isSet: boolean;
  allSets: Set[];
  productEdited: () => void;
}) {
  const atcBtnStyle = `pt-3 pb-3 bg-palette-primary text-white w-full mt-8 rounded-lg font-medium text-xl flex 
  justify-center items-baseline hover:bg-palette-dark`;

  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [editProduct, setEditProduct] = useState<boolean>(false);

  const [formData, setFormData] = useState<NewProductData>({
    category: product.category,
    title: product.title,
    description: product.description,
    price: product.price,
    discount: product.discount,
    images: [],
  });

  const [coverImageFile, setCoverImageFile] = useState<File>();
  const [productImageFiles, setProductImageFiles] = useState<File[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isCategoryValid, setIsCategoryValid] = useState<boolean>(true);
  const [isSubCategoryValid, setIsSubcategoryValid] = useState<boolean>(true);
  const handleFormDataChange = (formData: NewProductData) => {
    setFormData(formData);
  };

  const handleCoverImageChange = (coverImage: File) => {
    setCoverImageFile(coverImage);
  };

  const handleProductImagesChange = (productImages: File[]) => {
    setProductImageFiles(productImages);
  };

  const handleImageToDeleteChange = (imageToDelete: string) => {
    setImagesToDelete((prevImages) => [...prevImages, imageToDelete]);
  };

  const { handleDeleteProduct, handleEditProduct } = useAdminModelController(
    productRepository,
    quotesRepository
  );

  const navigate = useNavigate();

  const deleteProduct = async () => {
    try {
      setShowLoader(true);
      const result = await handleDeleteProduct(product);

      if (result) {
        setShowLoader(false);

        showSuccessToast("Il prodotto è stato eliminato con successo!");
        navigate("/dashboard");
      } else {
        setShowLoader(false);

        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
        );
      }
    } catch (error) {
      setShowLoader(false);

      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
      );
    }
  };

  const updateProduct = async () => {
    try {
      if (!isCategoryValid) {
        showErrorToast("Inserisci una categoria valida");
        return;
      }
      if (!isSubCategoryValid) {
        showErrorToast("Inserisci una sotto categoria valida");
        return;
      }

      setShowLoader(true);

      const result = await handleEditProduct(
        product.id,
        formData,
        product.images,
        coverImageFile,
        productImageFiles,
        imagesToDelete
      );
      if (result) {
        setShowLoader(false);
        showSuccessToast("Il prodotto è stato modificato con successo!");
        productEdited();
      } else {
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
        );
      }
    } catch (error) {
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
      );
    }
    setShowLoader(false);
    setEditProduct(false);
  };

  const goBack = () => {
    navigate("/dashboard");
  };

  if (showLoader) {
    return <LoaderView />;
  }

  return (
    <>
      {editProduct ? (
        <EditProductImageComponent
          product={product}
          onCoverImageChange={handleCoverImageChange}
          onProductImagesChange={handleProductImagesChange}
          onImageToDeleteChange={handleImageToDeleteChange}
        />
      ) : (
        product && <ProductImageComponent product={product} />
      )}
      <div className="md:flex-col justify-between h-full w-full md:w-1/2 max-w-xs mx-auto space-y-4 min-h-128">
        <div className="flex space-x-2">
          {isSet ? null : <BackToProductButton goBack={goBack} />}
          <button
            aria-label="back-to-products"
            className="w-3/5 border border-palette-primary text-palette-primary text-lg  font-medium pt-2 pb-2 leading-relaxed flex 
      justify-center items-center focus:ring-1 focus:ring-palette-light focus:outline-none hover:bg-palette-lighter rounded-lg"
            onClick={() => setEditProduct(true)}
          >
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="w-4 mr-2 inline-flex"
            />
            Modifica
          </button>
        </div>
        {editProduct ? (
          <>
            <EditProductInfoComponent
              product={product}
              allSets={allSets}
              onFormDataChange={handleFormDataChange}
              onCategoryChanged={(valid) => setIsCategoryValid(valid)}
              onSubcategoryChanged={(valid) => setIsSubcategoryValid(valid)}
            />
            <div className="flex space-x-2">
              <button
                className={atcBtnStyle}
                aria-label="cart-button"
                onClick={() => setEditProduct(false)}
              >
                Annulla
                <FontAwesomeIcon icon={faXmark} className="w-5 ml-2 my-auto" />
              </button>
              <button
                className={atcBtnStyle}
                aria-label="cart-button"
                onClick={updateProduct}
              >
                Salva
                <FontAwesomeIcon
                  icon={faFloppyDisk}
                  className="w-5 ml-2 my-auto"
                />
              </button>
            </div>
          </>
        ) : (
          <>
            <ProductInfoComponent product={product} />
            <button
              className={atcBtnStyle}
              aria-label="cart-button"
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Elimina prodotto
              <FontAwesomeIcon icon={faTrash} className="w-5 ml-2 my-auto" />
            </button>
          </>
        )}
      </div>
      <ConfirmDialogComponent
        type={DialogType.Delete}
        title="Elimina prodotto"
        body="Sei sicuro di voler eliminare il prodotto? Tutti i
                          dati relativi ad esso verranno rimossi
                          definitivamente. Questa azione non può essere
                          annullata."
        open={confirmDeleteDialog}
        onClose={() => setConfirmDeleteDialog(false)}
        onConfirm={deleteProduct}
      />
    </>
  );
}
